import React from 'react';
import ReactMarkdown from 'react-markdown';
import Block from '../../adapters/helpers/Block'
import PropTypes from 'prop-types';
import htmlParser from 'react-markdown/plugins/html-parser';
import { MarkdownTextConstants } from '../../adapters/helpers/Constants'

const astPlugins = [
    htmlParser({
        isValidNode: node => node.type !== MarkdownTextConstants.script,
        processingInstructions: [/* ... */]
    }),
];

export function Markdown({ source, className }) {
    return (
        <ReactMarkdown
          source={source}
          className={className}
          escapeHtml={false}
          astPlugins={astPlugins}
        />
    );
}

Markdown.propTypes = {
    source: PropTypes.any,
    className: PropTypes.string,
}

export default function MarkdownText(props) {
    const block = new Block(props);
    const document = block.getDocument();
    const classNames = document?.fields?.classNames;
    let ariaHidden = null;
    if (classNames && classNames.indexOf('aria-hidden') >= 0) {
        ariaHidden = true;
    }
    const Tag = props.isSpan ? 'span' : 'div';

    const renderers = {
        link: ({ children, href }) => (
            <a href={href} className="event_internal_link" data-action-detail={href}>
            {children}
            </a>
        )
    };

    return ((document?.fields) ?
        <Tag className={classNames} aria-hidden={ariaHidden}>
           <ReactMarkdown source={block.getFieldValue(MarkdownTextConstants.text)} escapeHtml={false} astPlugins={astPlugins} />
        </Tag>
    :
        <Tag className={props?.className} aria-hidden={ariaHidden}>
            <ReactMarkdown renderers={renderers} source={props?.value} escapeHtml={false} astPlugins={astPlugins} />
        </Tag>
    );
}

MarkdownText.propTypes = {
    value: PropTypes.string,
    isSpan: PropTypes.bool,
    className: PropTypes.string,
};
